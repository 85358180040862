exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-device-identify-js": () => import("./../../../src/pages/device-identify.js" /* webpackChunkName: "component---src-pages-device-identify-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-gallery-gallery-js": () => import("./../../../src/templates/Gallery/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-gallery-js" */),
  "component---src-templates-journal-journal-js": () => import("./../../../src/templates/Journal/Journal.js" /* webpackChunkName: "component---src-templates-journal-journal-js" */),
  "component---src-templates-project-project-js": () => import("./../../../src/templates/Project/Project.js" /* webpackChunkName: "component---src-templates-project-project-js" */)
}

